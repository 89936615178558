import PropTypes from "prop-types";
import { Popover } from "@mui/material";
import React from "react";

MenuPopover.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default function MenuPopover({
  children,
  sx,

  ...other
}) {
  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      elevation={0}
      PaperProps={{
        sx: {
          p: 1,
          width: 194,
          borderTop: "1px solid #ebebeb !important",
          borderRight: "1px solid #ebebeb !important",
          borderLeft: "1px solid #ebebeb !important",
          borderRadiusTopRight: "9px !important",
          boxShadow: "0!important",
          overflow: "inherit",
          // ...sx,
        },
      }}
      {...other}
    >
      {children}
    </Popover>
  );
}
